import { Injectable } from '@angular/core';
import { Router } from "@angular/router";
import { AngularFireAuth } from 'angularfire2/auth';
import * as firebase from 'firebase/app';
import { Observable } from 'rxjs';
import { FireStoreService } from '../fireStore/fire-store.service';


@Injectable()
export class AuthService {
  private user: Observable<firebase.User>;
  private userDetails: firebase.User = null;
  constructor(private _firebaseAuth: AngularFireAuth, private router: Router,private afStore:FireStoreService) { 
      this.user = _firebaseAuth.authState;
      this.user.subscribe(
        (user) => {
          if (user) {
            this.userDetails = user;
            localStorage.setItem('userData',JSON.stringify(this.userDetails));
            // alert("Welcome "+this.userDetails.displayName);
            this.router.navigate(['/home'])
          }
          else {
            this.userDetails = null;
          }
        }
      );
  
  }
  signInWithGoogle() {
    return this._firebaseAuth.auth.signInWithPopup(
      new firebase.auth.GoogleAuthProvider().setCustomParameters({
        prompt: 'select_account'
      })
    )
    // .then((res)=>{
    //   this.router.navigate(['/login'])
    // })
  }
  isLoggedIn() {
    if (this.userDetails == null ) {
        return false;
      } else {
        return true;
      }
  }
  getUserDetails(){
    return this.userDetails;
  }
  logout() {
      this._firebaseAuth.auth.signOut()
      .then((res) => {
        this.userDetails=null;
        localStorage.removeItem('userData');
        localStorage.clear();
        this.router.navigate(['/login'])
      });
    }

    public isAuthenticated(): boolean {
      const user = JSON.parse(localStorage.getItem('userData'));
      if (!user.email.includes('@shaale.com')) {
        return false;
      } else {
        
        this.afStore.getUserDetails(user.email).subscribe(resp=>{
          if(resp.size==0){
                let userDetails={
                  name:user.displayName,
                  email:user.email,
                  firstLogin:new Date(),
                  lastLogin:new Date()
                }
                this.afStore.addUserDetails(userDetails).then((resp)=>{  
                })
              }else{
                this.afStore.updateUserDetails(resp.docs[0].id).then(x=>{
                })
              }
        })
        return true;
      }
    }
}