import { Component } from '@angular/core';
import { AngularFireAuth } from 'angularfire2/auth';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Shaale-Abhyas-firebase';
  navBarFlag:boolean=true;

   constructor(private _firebaseAuth: AngularFireAuth){
    _firebaseAuth.authState.subscribe(resp=>{
      if(resp){
        if (resp.email.includes('@shaale.com')) {
          this.unHideNavBar();
        }
        else{
          this.displayNotShaaleUser('Oops! You are not autherized user. try login with shaale account');
        }
      }
    })
   }
  unHideNavBar(){
    this.navBarFlag=false;
  }
  hideNavBar(){
    this.navBarFlag=true;
  }
  displayNotShaaleUser(msg){
    document.getElementById("alarmmsg").innerHTML = msg;

    setTimeout(function(){
        document.getElementById("alarmmsg").innerHTML = '';
    }, 3000);
  }
  

}
