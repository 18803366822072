import { Component, OnInit , Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material';

@Component({
  selector: 'app-delete-confirmation',
  template: `  
  <div class="focus">
  <div class="row">
  <h1 mat-dialog-title>{{msg}}  </h1>    
    <div class="col-12 text-center">
      <mat-dialog-actions>  
          <button class="btn btn-primary btn-lg" mat-raised-button [mat-dialog-close]="true" [disabled]=!enableYes>YES</button>  
          <button class="btn btn-danger btn-lg" style="margin-left:10px;" mat-raised-button [mat-dialog-close]="false">NO</button>  
      </mat-dialog-actions>
    </div>
  </div>
</div>
`  ,
  styleUrls: ['./delete-confirmation.component.css']
})
export class DeleteConfirmationComponent implements OnInit {
msg:any;
enableYes:boolean=true;
  constructor(@Inject(MAT_DIALOG_DATA) public data:any) { 
      if(data.reference>0){
        this.msg=" You cannot Delete "+data.message+".Since it has references"
        this.enableYes=false;
      }else{
        this.msg="Are you sure want to delete "+data.message+" ?"
      }
    }

  ngOnInit() {
  }

}
