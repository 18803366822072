import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import {NgxPaginationModule} from 'ngx-pagination';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { AngularFireModule } from 'angularfire2';
import { AngularFirestoreModule } from 'angularfire2/firestore';
import { AngularFireStorageModule } from 'angularfire2/storage';

import { RouterModule } from '@angular/router';
import { environment } from 'src/environments/environment';
import { NavComponent } from './nav/nav.component';

import { AddSongService } from './add-song/add-song.service';
import { MasterService } from './master/master.service';
import { FireStoreService } from './services/fireStore/fire-store.service';
import { FireStorageService } from './services/fireStorage/fire-storage.service';
import { HomeService } from './home/home.service';
import { EditSongService } from './edit-song/edit-song.service';
import { SortableColumnService } from './sortable-column/sortable-column.service';

import { AngularFireAuthModule } from 'angularfire2/auth';
import { AuthService } from './services/auth/auth.service';
import { HomeComponent } from './home/home.component';
import { MasterComponent } from './master/master.component';
import { LoadingSpinnerComponent } from './ui/loading-spinner/loading-spinner.component';
import { AddSongComponent } from './add-song/add-song.component';
import { EditSongComponent } from './edit-song/edit-song.component';
import { SortableColumnComponent } from './sortable-column/sortable-column.component';
import { SortableTableDirective } from './directives/sortable-table.directive';
import { AuthGuardService as AuthGuard } from './services/authGuard/auth-guard.service';
import { LoginComponent } from './login/login.component';

import { SelectDropDownModule } from 'ngx-select-dropdown';
import { MasterMigrationComponent } from './migration/master-migration/master-migration.component';
import { MasterMigrationService } from './migration/master-migration/master-migration.service';
import { DeleteConfirmationComponent } from './ui/model/delete-confirmation/delete-confirmation.component';
import {MatDialogModule} from '@angular/material';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';


@NgModule({
  declarations: [
    AppComponent,
    NavComponent,
    HomeComponent,
    HomeComponent,
    MasterComponent,
    LoadingSpinnerComponent,
    AddSongComponent,
    EditSongComponent,
    SortableColumnComponent,
    SortableTableDirective,
    LoginComponent,
    MasterMigrationComponent,
    DeleteConfirmationComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    NgxPaginationModule,
    SelectDropDownModule,
    MatDialogModule,
    BrowserAnimationsModule,
    /*
    * imports of Angular firestore
    * imports of Angular firestorage
    */
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireStorageModule,
    AngularFireAuthModule,
    RouterModule.forRoot([
      {
        path: '', 
        redirectTo: 'login', 
        pathMatch: 'full'},
      {
        path:'home/editSong/:songid',
        component:EditSongComponent,
        canActivate:[AuthGuard]
      },
      {
        path:'home',
        component:HomeComponent,
        canActivate:[AuthGuard]
      },
      {
        path:'addSong',
        component:AddSongComponent,
        canActivate:[AuthGuard]
      },
      {
        path:'login',
        component:LoginComponent
      },
      {
        path:'master/:masterName',
        component:MasterComponent,
        canActivate:[AuthGuard]
      },
      {
        path:'migration',
        component:MasterMigrationComponent,
        canActivate:[AuthGuard]
      }
    ])
  ],
  providers: [
  AddSongService,
  MasterService,
  HomeService,
  EditSongService,
  SortableColumnService,
  MasterMigrationService,
  AuthService,
  AuthGuard,

  FireStoreService,
  FireStorageService
],
  bootstrap: [AppComponent],
  entryComponents:[DeleteConfirmationComponent]
})
export class AppModule { 
  
}
