import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from 'angularfire2/firestore';
import { Observable, Observer } from 'rxjs';

@Injectable({
  providedIn: 'root'
})



export class MasterService {
  items: Observable<any[]>;
  masterData: any;
  master: any;
  constructor(private db: AngularFirestore) { }

  add(collectionName, value) {
    this.db.collection(collectionName).add(value);
  }

  getMaster(masterName) {
    this.masterData = null;
    this.items = this.db.collection("master", ref => ref.where('name', '==', masterName)).valueChanges();
    this.items.subscribe(x => {
      let obj = new Object(x);
      this.masterData = obj[0].value;
    })
    return this.items;
  }
  deleteInMaster(masterName, value) {
    let originalIndex = this.masterData.map(function (e) { return e.id; }).indexOf(value.id);
    this.masterData.splice(originalIndex, 1);
    return this.addToMaster(masterName, this.masterData);
  }
  updateInMaster(masterName, value) {
    let originalIndex = this.masterData.map(function (e) { return e.id; }).indexOf(value.id);
    this.masterData[originalIndex].name = value.name;
    return this.addToMaster(masterName, this.masterData);
  }
  addNewElementToMaster(masterName, value) {
    this.masterData.push(value);
    return this.addToMaster(masterName, this.masterData);
  }
  addToMaster(masterName, value) {
    return this.db.collection('master', ref => ref.where('name', '==', masterName).limit(1))
      .get().forEach((quers) => {
        let _id = quers.docs[0].id;
        return this.db.collection('master').doc(_id).update({ value: value })
      });
  }

  getReferenceCount(attributeName, id) {
   
    return new Promise((response, error) => {
      return this.db.collection('songs', 
      ref => ref.where(attributeName+'.id', '==', id).where('isDeleted','==',false))
      .get().forEach((quers) => {
        response(quers.size);
      })
    })
  }
  updateIndividualProfiles(masterName, value) {
    this.master = masterName;
    var updatingObj = {
    }
    updatingObj[this.master] = value;
    if(this.master=='artist'){
      // Also check for accompanying artist array
    }
    return this.db.collection('songs', ref => ref.where(masterName + '.id', '==', value.id)).get().forEach(resp => {
      if (resp.size > 0) {
        this.updateWebMasterProfiles(resp, masterName, value).then(() => {
          return this.db.collection('songs').doc(resp.docs[0].id).update(updatingObj)
        })
      }
    })
  }
  /*
  *updating the web master
  */
  updateWebMasterProfiles(resp, masterName, value) {
    return new Promise((respose, error) => {
      // If specific attribute value is been change we may have to update 
      if (masterName == 'artist' || masterName == 'raga' ||
        masterName == 'tala' || masterName == 'type' || masterName == 'category') {
        let songId = resp.docs[0].id;
        this.db.collection('master', ref => ref.where('name', '==', 'songs').limit(1)).get().forEach((quers) => {
          
          let songsMasterId = quers.docs[0].id;
          let beforeValue = quers.docs[0].get('value');
          //Getting based on songId
          let index = beforeValue.findIndex(x => x.songId == songId)
          //copying to temp var to update value
          var tempObject = beforeValue[index];
          tempObject[this.master] = value.name;
          //Putting back the updated object
          beforeValue[index] = tempObject;
          this.db.collection('master').doc(songsMasterId).update({ value: beforeValue }).then(resp => {
            respose();
          })
        });
      }
      else {
        respose();
      }
    })
  }
}