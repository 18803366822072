import { Directive } from '@angular/core';

import {  OnInit, EventEmitter, Output, OnDestroy, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { SortableColumnService } from '../sortable-column/sortable-column.service';

@Directive({
  selector: '[sortable-table]'
})
export class SortableTableDirective {

  constructor(private sortService:SortableColumnService) { }

  @Output()
    sorted = new EventEmitter();

    private columnSortedSubscription: Subscription;

    ngOnInit() {
        this.columnSortedSubscription = this.sortService.columnSorted$.subscribe(event => {
            this.sorted.emit(event);
        });
    }

    ngOnDestroy() {
        this.columnSortedSubscription.unsubscribe();
    }

}
