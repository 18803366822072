import { Component, OnInit } from '@angular/core';
import { v4 as uuid } from 'uuid';
import { MasterMigrationService } from './master-migration.service';

@Component({
  selector: 'app-master-migration',
  templateUrl: './master-migration.component.html',
  styleUrls: ['./master-migration.component.css']
})
export class MasterMigrationComponent implements OnInit {
  migrationData: any;
  masterName: any;
  loader: boolean;
  artists: any = [];
  types: any[];
  composers: any[];
  ragas: any[];
  talas: any[];
  categories: any[];
  languages: any[];
  deities: any[];
  producers: any[];
  collections: any[];
  durations: any[];

  homeAttributeName:string;
  homeDestinationName:string;
  attributeName:string;
  destinationName:string;
  constructor(private service: MasterMigrationService) { }

  ngOnInit() {
    // this.loader = true;
    // this.service.getMaster('artist').subscribe(master => {
    //   let obj = new Object(master);
    //   this.artists = obj[0].value;
    //   this.service.getMaster('type').subscribe(master => {
    //     let obj = new Object(master);
    //     this.types = obj[0].value;
    //     //Composer from master
    //     this.service.getMaster('composer').subscribe(master => {
    //       let obj = new Object(master);
    //       this.composers = obj[0].value;
    //       //Raga from master
    //       this.service.getMaster('raga').subscribe(master => {
    //         let obj = new Object(master);
    //         this.ragas = obj[0].value;
    //         //Tala from master
    //         this.service.getMaster('tala').subscribe(master => {
    //           let obj = new Object(master);
    //           this.talas = obj[0].value;
    //           //category from master
    //           this.service.getMaster('category').subscribe(master => {
    //             let obj = new Object(master);
    //             this.categories = obj[0].value;
    //             this.service.getMaster('deities').subscribe(master => {
    //               let obj = new Object(master);
    //               this.deities = obj[0].value;
    //               //producers from master
    //               this.service.getMaster('producer').subscribe(master => {
    //                 let obj = new Object(master);
    //                 this.producers = obj[0].value;
    //                 //producers from master
    //                 this.service.getMaster('collection').subscribe(master => {
    //                   let obj = new Object(master);
    //                   this.collections = obj[0].value;
    //                   //producers from master
    //                   this.service.getMaster('duration').subscribe(master => {
    //                     let obj = new Object(master);
    //                     this.durations = obj[0].value;
    //                     this.service.getMaster('language').subscribe(master => {
    //                       let obj = new Object(master);
    //                       this.languages = obj[0].value;
    //                       this.loader = false;
    //                     })
    //                   })
    //                 })
    //               })
    //             })
    //           })
    //         })
    //       })
    //     })
    //   })
    // })
  }

  migrate() {
    var temp = this.migrationData.split(',');
    let arr = [];
    let count = 1;
    // temp.forEach(data => {
    //   let obj = {
    //     id: uuid(),
    //     name:data
    //   }
    //   arr.push(obj);
    //   count++;
    // })
    let container = { name: this.masterName, value: arr };
    console.log("check item :" + JSON.stringify(container));
    this.service.addToMaster("master", container);
  }

  mirgateObject() {
    let obj = JSON.parse(this.migrationData);
    console.log(obj.length);
    let container = { name: this.masterName, value: obj };
    this.service.addToMaster("appMaster", container);
  }

  mirgrateToCollectionLyrics() {
    var temp = [];
    temp.forEach(data => {
      let item = { name: data.name, description: data.description }
      this.service.addToMaster(this.masterName, item);
    })
  }
  startUpdating() {
    this.service.getAllSongsData().subscribe(x => {
      x.docs.forEach(data => {
        let id = data.id;
        let song = data.data();

        if(song.language!=undefined && song.language!=null){
          var artistObj = this.artists.find(x => x.name == song.artist)
          song.artist = artistObj;
        }
        if(song.category!=undefined && song.category!=null){
        var categoryObj = this.categories.find(x => x.name == song.category)
        song.category = categoryObj;
        }
        if(song.collection!=undefined && song.collection!=null){
          var collectionObj = this.collections.find(x => x.name == song.collection)
          song.collection = collectionObj;
        }
        if(song.composer!=undefined && song.composer!=null){
          var composerObj = this.composers.find(x => x.name == song.composer)
          song.composer = composerObj;
        }
        if(song.deities!=undefined && song.deities!=null){
          var deitiesObj = this.deities.find(x => x.name == song.deities)
          song.deities = deitiesObj;
        }
        if(song.duration!=undefined && song.duration!=null){
          var durationObj = this.durations.find(x => x.name == song.duration)
          song.duration = durationObj;
        }
        if(song.language!=undefined && song.language!=null){
          var languageObj = this.languages.find(x => x.name == song.language)
          song.language = languageObj;
        }
        if(song.producer!=undefined && song.producer!=null){
          var producerObj = this.producers.find(x => x.name == song.producer)
          song.producer = producerObj;
        }
        if(song.raga!=undefined && song.raga!=null){
          var ragaObj = this.ragas.find(x => x.name == song.raga)
          song.raga = ragaObj;
        }
        if(song.tala!=undefined && song.tala!=null){
          var talaObj = this.talas.find(x => x.name == song.tala)
          song.tala = talaObj;
        }
        if(song.type!=undefined && song.type!=null){
          var typeObj = this.types.find(x => x.name == song.type)
          song.type = typeObj;
        }
        if(song.accompanyingArtists!=undefined && song.accompanyingArtists!=null){
          var accompArtistArr=[];
          song.accompanyingArtists.split(',').forEach(accomp=>{
            var accompArtist=this.artists.find(x=> x.name == accomp.trim())
            accompArtistArr.push(accompArtist)
          })
          song.accompanyingArtists=accompArtistArr;
        }
        console.log(song);
        // this.service.updateSong(id,song).then(resp=>{
        //   console.log("updation success");
        // })
      })
    })
  }

  getRefernceCount(){
    var masterName='artist';
    var temp=[];
    var countMain=0;
    this.service.getMaster(masterName).subscribe(query=>{
      var data=new Object(query);
      var respCount=0;
      console.log("total count"+data[0].value.length)
      data[0].value.forEach(individual=>{
        this.service.getReferenceCount(masterName,individual.id).then(count=>{
          this.service.getCategory(individual).then(query=>{
            var respData=new Object(query);
            if(count>0 && respData[0]!=undefined){
              countMain++;
              let construct={
                name:individual.name,
                count:count,
                category:respData[0].data().category.name
              }
              temp.push(construct)
            }
            respCount++;
            console.log(respCount);
            if(data[0].value.length==respCount){
              console.log("update count now "+countMain);
              console.log(temp);
              this.service.updateAppMasterCount('Artists',temp.length).then(response=>{
                this.service.updateSpecificRefernceCount('Artists',temp).then(resp=>{
                  console.log('success');
                })
              })
            }
          })
          
        })
      })
    })
  }
  getSpecificRefernceCount(){
    var masterName=this.attributeName
    var temp=[];
    var countMain=0;
    this.service.getMaster(masterName).subscribe(query=>{
      var data=new Object(query);
      var respCount=0;
      console.log("total count"+data[0].value.length)
      data[0].value.forEach(individual=>{
        this.service.getReferenceCount(masterName,individual.id).then(count=>{
          if(count>0){
            countMain++;
            let construct={
              name:individual.name,
              count:count
            }
            temp.push(construct)
          }
          respCount++;
          console.log(respCount);
          if(data[0].value.length==respCount){
            console.log("update count now "+countMain);
            console.log(temp);
            this.service.updateAppMasterCount(this.destinationName,temp.length).then(response=>{
              this.service.updateSpecificRefernceCount(this.destinationName,temp).then(resp=>{
                console.log('success');
              })
            })
          }
          
        })
      })
    })
  }

  
  updateSongsAToZCount(){
    this.service.getAllSongsData().subscribe(resp=>{
     this.service.updateSongsAToZCount(resp.size)
     .then(resp=>{
       console.log('updated succesfully!!');
     })

    })
  }

}
