import { Component, OnInit} from '@angular/core';
import { MatDialog } from '@angular/material'; 

import { HomeService } from './home.service';
import { AngularFireModule } from 'angularfire2';
import { DeleteConfirmationComponent } from '../ui/model/delete-confirmation/delete-confirmation.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  songsList:any[];
  search:string="";
  loader:boolean=true;
  enableBlurCSS:boolean=false;
  p:any;
  
  constructor(private homeService:HomeService,private dialog: MatDialog) { 
  }

  ngOnInit() {
    this.getSongsFromMaster();
  }
  getSongsFromMaster(){
    this.homeService.getMasterSongs().subscribe(data=>{
      if(data.length>0){
      let obj= new Object(data);
      this.songsList=obj[0].value;
      let count=1;
      this.songsList.forEach(song=>{
        song.genaratedId=count;
        count++;
      })
    }
      this.loader=false;
    })
  }
  searchMethod($event){
    let q = $event.target.value.toLowerCase();
    this.homeService.getMasterSongs().subscribe(data=>{
      let obj= new Object(data);
      this.songsList=obj[0].value;
      let count=1;
      this.songsList.forEach(song=>{
        song.genaratedId=count;
        count++;
      })
      this.songsList=obj[0].value.filter(x=>x.title.toLowerCase().includes(q)
      ||x.raga.toLowerCase().includes(q)
      ||x.tala.toLowerCase().includes(q)
      ||x.artist.toLowerCase().includes(q)
      ||x.type.toLowerCase().includes(q)
      ||x.category.toLowerCase().includes(q)
      );
    })
  }

  onSorted($event){
    this.songsList=this.songsList.sort(
      this.dynamicSort($event.sortColumn,$event.sortDirection)
      );
  } 

  dynamicSort(property,order) {
    if(order=='asc'){
      return function (a, b) {
          let result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
          return result;
      } 
    }
    else{
      return function (b, a) {
        let result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
        return result;
      } 
    }
  } 
  
  delete(value){
    this.loader=true;
    // this.homeService.deleteFolder(value.songId).then((resp)=>{
    // })
        this.homeService.deleteOriginalSong(value.songId).then(x=>{
          this.homeService.deleteSongFromMaster(value).then(x=>{
            this.homeService.removeFromAppMaster(value.songId).then(()=>{
              this.loader=false;
            })
          })
        })
  }
  deletePostConfirm(post) {    
    this.enableBlurCSS=true;
    const dialogRef = this.dialog.open(DeleteConfirmationComponent, {
      data: {
        message:post.title,
        reference:0
      }
    });   
    dialogRef.afterClosed().subscribe(confirmresult => {   
      if (confirmresult) {    
        this.delete(post); 
      } 
      this.enableBlurCSS=false;
    });  
  }  
}
  

