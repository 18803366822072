import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MasterService } from './master.service';
import { Observable, ObjectUnsubscribedError } from 'rxjs';
import { element } from '@angular/core/src/render3';
import {v4 as uuid} from 'uuid';
import { MatDialog } from '@angular/material';
import { DeleteConfirmationComponent } from '../ui/model/delete-confirmation/delete-confirmation.component';


@Component({
  selector: 'app-master',
  templateUrl: './master.component.html',
  styleUrls: ['./master.component.css']
})
export class MasterComponent implements OnInit {
  master:string;
  data:any[];
  loader:boolean=true;
  p:any;
  flagToAdd:boolean=true;
  masterValue:string;
  enableBlurCSS:boolean=false;


  constructor(private route: ActivatedRoute,private service:MasterService,private dialog: MatDialog) { 

  }

  ngOnInit() {
    this.route.paramMap
    .subscribe(param=>{
      this.master=param.get('masterName');
      this.service.getMaster(this.master).
      subscribe(master=>{
        let obj= new Object(master);
        this.data=obj[0].value;
        let count=1;
        this.data.forEach(temp=>{
          temp.genratedId=count;
          temp.editable=false;
          count++;
        })
        this.loader=false;
      })
      
    })
    
  }

  
  onSorted($event){
    this.data=this.data.sort(
      this.dynamicSort($event.sortColumn,$event.sortDirection)
      );
  } 

  dynamicSort(property,order) {
    if(order=='asc'){
      return function (a, b) {
          let result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
          return result;
      } 
    }
    else{
      return function (b, a) {
        let result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
        return result;
      } 
    }
  }
  searchMethod($event){
    let q = $event.target.value.toLowerCase();
    this.service.getMaster(this.master).subscribe(data=>{
      let obj= new Object(data);
      this.data=obj[0].value.filter(x=>x.name.toLowerCase().includes(q));
      let count=1;
      this.data.forEach(temp=>{
        temp.genratedId=count;
        temp.editable=false;
        count++;
      })
    })
  }
  addToMaster(masterName){
    this.flagToAdd=true;
    this.loader=true;
    let obj={
      id:uuid(),
      name:this.masterValue
    }
    // this.data.push(obj);
    // let temp=this.data
    // temp.forEach(x=>{
    //   delete x.editable,
    //   delete x.genratedId
    // })
    this.service.addNewElementToMaster(masterName,obj).then((resp)=>{
      this.masterValue="";
      this.loader=false;
      // this.ngOnInit();
    })
  }
  unHideMaster(){
    if(this.flagToAdd==true){
      this.flagToAdd=false;
    }else{
      this.flagToAdd=true;
    }
  }
  delete(value){
    this.loader=true;
    let index=this.data.indexOf(value);
    this.data.splice(index,1);
    this.service.deleteInMaster(this.master,value).then((resp)=>{
      this.loader=false;
    })
  }
  edit(value){
    this.data.forEach(temp=>{
      temp.editable=false;
    })
    let index=this.data.indexOf(value);
    this.data[index].editable=true;
  }
  disableEdit(){
    this.data.forEach(temp=>{
      temp.editable=false;
    })
  }
  save(value){
    this.loader=true;
    let index=this.data.indexOf(value);
    this.data[index].editable=false;
    let temp=this.data
    temp.forEach(x=>{
      delete x.editable,
      delete x.genratedId
    })
    delete value.editable;
    delete value.genratedId;
    
    this.service.updateInMaster(this.master,value).then((resp)=>{
      this.service.updateIndividualProfiles(this.master,value).then(resp=>{
          this.loader=false;
      })
    })
  }
  deletePostConfirm(post) {    
    this.enableBlurCSS=true;
    this.service.getReferenceCount(this.master,post.id).then((resp)=>{
      const dialogRef = this.dialog.open(DeleteConfirmationComponent, {
        data: {
          message:post.name,
          reference:resp
        }
      });   
      dialogRef.afterClosed().subscribe(confirmresult => {   
        if (confirmresult) {    
          this.delete(post); 
        } 
        this.enableBlurCSS=false;
      });  
    })
  }  
  
}