import { Injectable } from '@angular/core';
import { AngularFirestore } from 'angularfire2/firestore';

@Injectable({
  providedIn: 'root'
})
export class MasterMigrationService {

  constructor(private db :AngularFirestore) { }
  
  addToMaster(collectionName,value){
    this.db.collection(collectionName).add(value);
  }
  addToWebMaster(masterName,data){
    return this.db.collection('master',ref=>ref.where('name','==',masterName).limit(1))
    .get().forEach((quers)=>{
      let value=quers.docs[0].get('value');
      let localData={
        title:data.title,
        raga:data.raga,
        tala:data.tala,
        artist:data.artist,
        pitch:data.pitch,
        type:data.type,
        category:data.category,
        dateString:new Date().toDateString(),
        date:new Date(),
        songId:data.songId
      };
      value.push(localData);
      let _id=quers.docs[0].id;
      return this.db.collection('master').doc(_id).update({value:value})
    });
  }
  getMaster(masterName){
    return this.db.collection("master",ref=> ref.where('name','==',masterName)).valueChanges();
  }
  getAllLyricsFromMaster(){
    return this.db.collection("lyrics").get();
  }
  getAllSongsData(){
    return this.db.collection("songs").get();
  }
  updateSong(id,data){
    return this.db.collection('songs').doc(id).update(data).then(resp=>{
      return resp;
    })
  }
  getReferenceCount(masterName,id){
    return new Promise((response, error) => {
      return this.db.collection('songs', 
      ref => ref.where(masterName+'.id', '==', id).where('isDeleted','==',false))
      .get().forEach((quers) => {
        response(quers.size);
      })
    })
  }
  updateSongsAToZCount(count){
    return this.db.collection('appMaster',ref=>ref.where('name','==','Home')).get().forEach(data=>{
      let _id=data.docs[0].id;
      let temp=data.docs[0].data().value;
      let index=temp.findIndex(x=>x.name=='Songs A-Z')
      temp[index].count=count;
      console.log(temp);
      return this.db.collection('appMaster').doc(_id).update({value:temp}).then(resp=>{
        return resp;
      })
    })
  }
  updateAppMasterCount(masterName,count){
    return this.db.collection('appMaster',ref=>ref.where('name','==','Home')).get().forEach(data=>{
      let _id=data.docs[0].id;
      let temp=data.docs[0].data().value;
      let index=temp.findIndex(x=>x.name==masterName)
      temp[index].count=count;
      console.log(temp);
      return this.db.collection('appMaster').doc(_id).update({value:temp}).then(resp=>{
        return resp;
      })
    })
  }
  updateSpecificRefernceCount(mastername,Arraydata){
    return this.db.collection('appMaster',ref=>ref.where('name','==',mastername)).get().forEach(data=>{
      let _id=data.docs[0].id;
      return this.db.collection('appMaster').doc(_id).update({value:Arraydata}).then(resp=>{
        return resp;
      })
    })
  }
  getCategory(artist){
    return new Promise((response, error) => {
       this.db.collection('songs',ref=>ref.where('artist.id','==',artist.id)
      .where('isDeleted','==',false)).get().forEach(query=>{
          response(query.docs) 
      })
    })
  }
}
