import { Component, OnInit } from '@angular/core';
import {Input, EventEmitter, OnDestroy, HostListener } from '@angular/core';
import { SortableColumnService } from './sortable-column.service';

import { Subscription } from 'rxjs';


@Component({
  selector: '[sortable-column]',
  templateUrl: './sortable-column.component.html',
  styleUrls: ['./sortable-column.component.css']
})
export class SortableColumnComponent implements OnInit {

  constructor(private sortService: SortableColumnService) { }

  @Input('sortable-column')
    columnName: string;

    @Input('sort-direction')
    sortDirection: string = '';

    private columnSortedSubscription: Subscription;

    @HostListener('click')
    sort() {
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
      this.sortService.columnSorted({ sortColumn: this.columnName, sortDirection: this.sortDirection });
    }

  ngOnInit() {
    this.columnSortedSubscription = this.sortService.columnSorted$.subscribe(event => {
      // reset this column's sort direction to hide the sort icons
      if (this.columnName != event.sortColumn) {
          this.sortDirection = '';
      }
  });
  }

  ngOnDestroy() {
    this.columnSortedSubscription.unsubscribe();
}

}
