import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from 'angularfire2/firestore';

@Injectable({
  providedIn: 'root'
})
export class FireStoreService {

  constructor(private angualarFireStore :AngularFirestore) { }

  getFireStoreConnection(){
    return this.angualarFireStore;
  }
  getUserDetails(email){
    return this.angualarFireStore.collection('user',ref=>ref.where('email','==',email)).get()
  }
  updateUserDetails(_id){
    return this.angualarFireStore.collection('user').doc(_id).update({lastLogin:new Date()})
  }
  addUserDetails(userDetails){
    return this.angualarFireStore.collection('user').add(userDetails)
  }
}