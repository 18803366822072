import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth/auth.service';
import { AngularFireAuth } from 'angularfire2/auth';
import { AppComponent } from '../app.component';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.css']
})
export class NavComponent implements OnInit {
  
  userDetails:any=null;
  showLogout:boolean=true;
  
  
  constructor(private AuthService:AuthService,private _firebaseAuth: AngularFireAuth,private appComponent: AppComponent) { 
    
    _firebaseAuth.authState.subscribe(resp=>{
      if(resp){
        this.showLogout=false;
        this.userDetails=JSON.parse(JSON.stringify(resp));
      }
    })
  }

  ngOnInit() {
  }
  

  logout(){
    this.AuthService.logout();
    this.appComponent.hideNavBar()
    this.userDetails.displayName=" ";
    this.showLogout=true;
  }

}
