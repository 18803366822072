import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EditSongService } from './edit-song.service';

import { FireStorageService } from '../services/fireStorage/fire-storage.service';
import { Router, ParamMap } from '@angular/router';
import { v4 as uuid } from 'uuid';

@Component({
  selector: 'app-edit-song',
  templateUrl: './edit-song.component.html',
  styleUrls: ['./edit-song.component.css']
})
export class EditSongComponent implements OnInit {
  songId: any;
  song: any = {
    title: " "
  };
  title: string;
  artists: any[];
  types: any[];
  composers: any[];
  ragas: any[];
  talas: any[];
  categories: any[];
  languages: any[];
  deities: any[];
  producers: any[];
  collections: any[];
  durations: any[];
  audioPreview: any;

  subscriptions: string[] = ["free", "paid"];
  lyricsMaster: any[] = [];
  trackVariations: any[] = [];
  lyricId: any;
  lyrics: any;
  afStorage: any;
  isLyricEditable: boolean = false;
  storageRef: any;
  loader: boolean = true;
  titleFromMaster: string;
  trackName: any;
  track: any;
  updatedTrack: any;

  config = {
    displayKey: "name", //if objects array passed which key to be displayed defaults to description,
    search: true,//enables the search plugin to search in the list
    placeholder: ' '
  }
  selectedArtist: any = [];
  selectedAccompanyingArtists: any = [];
  selectedType: any = [];
  selectedComposer: any = [];
  selectedRaga: any = [];
  selectedTala: any = [];
  selectedCategory: any = [];
  selectedLanguage: any = [];
  selectedDeities: any;
  selectedProducer: any;
  selectedCollection: any;
  selectedDuration: any;
  selectedLyrics: any;

  constructor(private route: ActivatedRoute, private editSongService: EditSongService,
    private fireStorageService: FireStorageService,
    private router: Router) { }

  ngOnInit() {
    this.loader = true;
    this.afStorage = this.fireStorageService.getAngularFireStorage();
    this.storageRef = this.fireStorageService.getStorageRef();

    this.route.paramMap
      .subscribe(param => {
        this.songId = param.get('songid');

        this.editSongService.getSong(this.songId)
          .subscribe(data => {
            this.song = data;
            this.titleFromMaster = data.title;
            this.lyricId = this.song.lyricsId;
            if (data.track != null) {
              this.track = data.track;
              this.afStorage.ref(data.track).getDownloadURL().subscribe(downloadURL => {
                this.audioPreview = downloadURL;
              });
              this.trackName = data.track.split('/')[3];
            } else {
              alert("track not found");
            }
            //Artist from master
            this.editSongService.getMaster('artist').subscribe(master => {
              let obj = new Object(master);
              this.artists = obj[0].value;
              //Type from master
              this.editSongService.getMaster('type').subscribe(master => {
                let obj = new Object(master);
                this.types = obj[0].value;
                //Composer from master
                this.editSongService.getMaster('composer').subscribe(master => {
                  let obj = new Object(master);
                  this.composers = obj[0].value;
                  //Raga from master
                  this.editSongService.getMaster('raga').subscribe(master => {
                    let obj = new Object(master);
                    this.ragas = obj[0].value;
                    //Tala from master
                    this.editSongService.getMaster('tala').subscribe(master => {
                      let obj = new Object(master);
                      this.talas = obj[0].value;
                      //category from master
                      this.editSongService.getMaster('category').subscribe(master => {
                        let obj = new Object(master);
                        this.categories = obj[0].value;
                        this.editSongService.getMaster('deities').subscribe(master => {
                          let obj = new Object(master);
                          this.deities = obj[0].value;
                          //producers from master
                          this.editSongService.getMaster('producer').subscribe(master => {
                            let obj = new Object(master);
                            this.producers = obj[0].value;
                            //producers from master
                            this.editSongService.getMaster('collection').subscribe(master => {
                              let obj = new Object(master);
                              this.collections = obj[0].value;
                              //producers from master
                              this.editSongService.getMaster('duration').subscribe(master => {
                                let obj = new Object(master);
                                this.durations = obj[0].value;
                                this.editSongService.getAllLyricsFromMaster().subscribe(x => {
                                  let temp = [];
                                  x.docs.forEach(x => {
                                    let lyric = {
                                      id: x.id,
                                      name: x.get('name'),
                                      value: x.get('value')
                                    }
                                    temp.push(lyric);
                                  });
                                  this.lyricsMaster = temp;
                                  //language from master
                                  this.editSongService.getMaster('language').subscribe(master => {
                                    let obj = new Object(master);
                                    this.languages = obj[0].value;

                                    // this.lyricsChange(); 
                                    let tempSelectedArtist = [];
                                    tempSelectedArtist.push(this.song.artist);
                                    this.selectedArtist = tempSelectedArtist;

                                    let tempSelectedType = [];
                                    tempSelectedType.push(this.song.type);
                                    this.selectedType = tempSelectedType;

                                    let tempSelectedComposer = [];
                                    tempSelectedComposer.push(this.song.composer);
                                    this.selectedComposer = tempSelectedComposer;

                                    let tempSelectedRaga = [];
                                    tempSelectedRaga.push(this.song.raga);
                                    this.selectedRaga = tempSelectedRaga;

                                    let tempSelectedTala = [];
                                    tempSelectedTala.push(this.song.tala);
                                    this.selectedTala = tempSelectedTala;

                                    let tempSelectedLanguage = [];
                                    tempSelectedLanguage.push(this.song.language);
                                    this.selectedLanguage = tempSelectedLanguage;

                                    let tempSelectedCategory = [];
                                    tempSelectedCategory.push(this.song.category);
                                    this.selectedCategory = tempSelectedCategory;

                                    if (this.song.deities != undefined && this.song.deities != null) {
                                      let tempSelectedDeities = [];
                                      tempSelectedDeities.push(this.song.deities);
                                      this.selectedDeities = tempSelectedDeities;
                                    }

                                    let tempSelectedProducer = [];
                                    tempSelectedProducer.push(this.song.producer);
                                    this.selectedProducer = tempSelectedProducer;

                                    let tempSelectedCollection = [];
                                    tempSelectedCollection.push(this.song.collection);
                                    this.selectedCollection = tempSelectedCollection;

                                    if (this.song.duration != undefined && this.song.duration != null) {
                                      let tempSelectedDuration = [];
                                      tempSelectedDuration.push(this.song.duration)
                                      this.selectedDuration = tempSelectedDuration;
                                    }


                                    this.selectedAccompanyingArtists = this.song.accompanyingArtists;


                                    
                                    let tempSelectedLyrics = [];
                                    let temp = this.lyricsMaster.find(x => x.id == this.lyricId);
                                    tempSelectedLyrics.push(temp)
                                    this.selectedLyrics = tempSelectedLyrics;



                                    this.lyricsChangeOnLoad();

                                    this.loader = false;

                                  });
                                });
                              })
                            })
                          })
                        })
                      });
                    });
                  });
                });
              });
            });
          });
      });

    this.subscriptions = ["free", "paid"];
    // this.durations=["0-5","5-10","10-15","15-20"]

  }
  upload($event) {
    let temp = {
      file: $event.target.files[0],
      fileName: $event.target.files[0].name
    }
    this.updatedTrack = temp;
  }
  uploadSong() {
    let obj = [];
    return new Promise((response, error) => {
      if (this.updatedTrack != null || this.updatedTrack != undefined) {
        let url = this.track;
        this.afStorage.storage.ref(url).delete();
        const filePath = 'songs/' + this.song.artist.name + '/' + this.song.title + '/' + this.updatedTrack.fileName;
        this.afStorage.upload(filePath, this.updatedTrack.file).then(x => {
        })
        this.song.track = filePath;
        response("")
      } else {
        this.song.track = this.track;
        response("")
      }
    })
  }

  updateSong() {
    this.loader = true;
    this.song.artist = this.selectedArtist[0];
    this.song.accompanyingArtists = [];
    this.selectedAccompanyingArtists.forEach(artist => {
      this.song.accompanyingArtists.push(artist);
    });
    this.song.type = this.selectedType[0];
    this.song.composer = this.selectedComposer[0];
    this.song.raga = this.selectedRaga[0];
    this.song.tala = this.selectedTala[0];
    this.song.category = this.selectedCategory[0];
    this.song.language = this.selectedLanguage[0];
    this.song.deities = this.selectedDeities[0];
    this.song.producer = this.selectedProducer[0];
    this.song.collection = this.selectedCollection[0];
    this.song.duration = this.selectedDuration[0];
    this.uploadSong().then(resp => {
      if (this.isLyricEditable == true) {
        let lyric = { name: this.song.title, value: this.lyrics };
        this.editSongService.addLyrics(lyric).then(id => {
          this.song.lyricsId = id;
          this.editSongService.updateSong(this.songId, this.song).then(() => {
            let changed = {
              title: this.song.title,
              raga: this.song.raga.name,
              tala: this.song.tala.name,
              artist: this.song.artist.name,
              type: this.song.type.name,
              category: this.song.category.name
            }
            this.editSongService.updateMaster(this.songId, changed).then(() => {
              this.loader = false;
              this.router.navigate(['/home']);
            })
          })
        })
      } else {
        this.editSongService.updateSong(this.songId, this.song).then(() => {
          let changed = {
            title: this.song.title,
            raga: this.song.raga.name,
            tala: this.song.tala.name,
            artist: this.song.artist.name,
            type: this.song.type.name,
            category: this.song.category.name
          }
          this.editSongService.updateMaster(this.songId, changed).then(() => {
            this.loader = false;
            this.router.navigate(['/home']);
          })
        })
      }
    })
  }
  makeLyricEditable() {
    this.isLyricEditable = true;
  }

  lyricsChange() {
    let temp = this.lyricsMaster.find(x => x.id == this.selectedLyrics[0].id);
    this.song.lyricsId = temp.id;
    this.lyrics = temp.value;
    this.isLyricEditable = false;
  }

  lyricsChangeOnLoad() {
    let temp = this.lyricsMaster.find(x => x.id == this.lyricId);
    this.song.lyricsId = temp.id;
    this.lyrics = temp.value;
    this.isLyricEditable = false;
  }



}
