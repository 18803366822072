import { Injectable } from '@angular/core';
import { FireStoreService } from '../services/fireStore/fire-store.service';
import { FireStorageService } from '../services/fireStorage/fire-storage.service';
import { Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HomeService {
  angularFireStore:any;
  afStorage:any;
  items:Observable<any[]>;
  masterData:any;

  constructor(private angularFireStoreConnectionService:FireStoreService,private fireStorageService:FireStorageService) {
    this.angularFireStore= angularFireStoreConnectionService.getFireStoreConnection();
    this.afStorage=fireStorageService.getAngularFireStorage();
   }

   getMasterSongs(){
     this.items=this.angularFireStore.collection('master',ref=>ref.where('name','==','songs')).valueChanges();
     this.items.subscribe(x=>{
       if(x.length>0){
         let obj= new Object(x);
         this.masterData=obj[0].value;
       }
     })
     return this.items;
   }

   search(searchString){
      return this.angularFireStore.collection('master', ref=>ref.where('name','array-contains',searchString)).valueChanges();
   }

   deleteSongFromMaster(value){
    let index=this.masterData.map(function(e) { return e.songId; }).indexOf(value.songId);
    this.masterData.splice(index,1);
    this.masterData.forEach(x=>{
      delete x.genaratedId
    })
    return this.angularFireStore.collection('master',ref=>ref.where('name','==','songs').limit(1))
    .get().forEach((quers)=>{
      let _id=quers.docs[0].id;
      return this.angularFireStore.collection('master').doc(_id).update({value:this.masterData})
    });
   }
   deleteOriginalSong(_id){
    const user = JSON.parse(localStorage.getItem('userData'));
     let obj={
       deletedBy:user.email,
       deletedOn:new Date()
     }
    return this.angularFireStore.collection('songs').doc(_id).update({isDeleted:true,deleteInfo:obj});
      // .delete();
   }
   deleteFolder(_id){
     return new Promise((response,error)=>{
       this.angularFireStore.collection('songs').doc(_id).valueChanges().subscribe((resp)=>{  
        if(resp.trackVariation!=null){
          resp.trackVariation.forEach(track=>{
          this.deleteFile(track.url)
         })
        }
       response();
     })
    })
   }
   deleteFile(url){
    return this.afStorage.storage.ref(url).delete();
   }
   removeFromAppMaster(songId) {
    return this.angularFireStore.collection('songs').doc(songId).get().forEach(querysnapshot => {
      var song = querysnapshot.data();
      return new Promise((respose, error) => {
        this.update_AToZ_Songs_RecentReleases_InAppMaster().then(() => {
          this.updateAppMaster('Type of Composition', song.type.name).then(() => {
            this.updateAppMaster('Artists', song.artist.name).then(() => {
              this.updateAppMaster('Tala-s', song.tala.name).then(() => {
                this.updateAppMaster('Raga-s', song.raga.name).then(() => {
                  this.updateAppMaster('Composers', song.composer.name).then(() => {
                    this.updateAppMaster('Categories', song.category.name).then(() => {
                      this.updateAppMaster('Languages', song.language.name).then(() => {
                        this.updateAppMaster('Producers', song.producer.name).then(() => {
                          this.updateAppMaster('Collections', song.collection.name).then(() => {
                            if(song.deities!=undefined){
                              this.updateAppMaster('Deities', song.deities.name).then(() => {
                                respose()
                              })
                            }else{
                              respose()
                            }
                          })
                        })
                      })
                    })
                  })
                })
              })
            })
          })
        })
      })
    })
  }

  updateAppMaster(attributName, attributValue) {
    return new Promise((respose, error) => {
      this.angularFireStore.collection('appMaster', ref => ref.where('name', '==', attributName)).get().forEach(quersnapshots => {
        let array = quersnapshots.docs[0].data().value;
        let index = array.findIndex(x => x.name == attributValue)
        if (array[index].count > 1) {
          array[index].count = array[index].count - 1;
        } else {
          array.splice(index, 1)
        }
        this.angularFireStore.collection('appMaster').doc(quersnapshots.docs[0].id).update({ value: array }).then(() => {
          this.angularFireStore.collection('appMaster', ref => ref.where('name', '==', 'Home')).get().forEach(quersnapshots => {
            let arr = quersnapshots.docs[0].data().value;
            let index = arr.findIndex(x => x.name == attributName)
            arr[index].count = array.length;
            this.angularFireStore.collection('appMaster').doc(quersnapshots.docs[0].id).update({ value: arr }).then(resp => {
              respose()
            })
          })
        })
      })
    })
  }
  update_AToZ_Songs_RecentReleases_InAppMaster() {
    return new Promise((respose, error) => {
      this.angularFireStore.collection('appMaster', ref => ref.where('name', '==', 'Home')).get().forEach(quersnapshots => {
        let arr = quersnapshots.docs[0].data().value;
        let index = arr.findIndex(x => x.name == 'Songs A-Z')
        arr[index].count = arr[index].count - 1;
        this.angularFireStore.collection('appMaster').doc(quersnapshots.docs[0].id).update({ value: arr }).then(resp => {
          respose()
        })
      })
    })
  }
}
