import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import * as firebase from 'firebase';
import { AngularFireAuth } from 'angularfire2/auth';
import { AuthService } from '../services/auth/auth.service';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent {

  constructor(private Googleservice:AuthService,private router: Router
   , private afAuth: AngularFireAuth
    ) {
    
   }

  ngOnInit() {
  }

  login(){
    this.Googleservice.signInWithGoogle();
  }
  

}
