import { Injectable } from '@angular/core';

/*
* Inject AngularFireStorage Service
*/
import { AngularFireStorage } from 'angularfire2/storage';

@Injectable({
  providedIn: 'root'
})
export class FireStorageService {

  constructor(private angularFireStorage: AngularFireStorage) { }

  getAngularFireStorage(){
    return this.angularFireStorage;
  }

  getStorageRef(){
    return this.angularFireStorage.ref;
  }
}
