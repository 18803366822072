import { Component, OnInit } from '@angular/core';
import { AddSongService } from './add-song.service';
import {AngularFireStorageReference,
   AngularFireUploadTask } from 'angularfire2/storage';
import { Observable } from 'rxjs';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';

import { FireStorageService } from '../services/fireStorage/fire-storage.service';



interface Song{
  title:string;
  artist:any;
  accompanyingArtists:any[];
  type:any;
  composer:any;
  raga:any;
  tala:any;
  category:any;
  language:any;
  deities:any;
  producer:any;
  collection:any;
  duration:any;
  subscription:any;
  date:Date;
  track:any;
  lyricsId:String;
}

@Component({
  selector: 'app-add-song',
  templateUrl: './add-song.component.html',
  styleUrls: ['./add-song.component.css']
})



export class AddSongComponent implements OnInit {
  ref: AngularFireStorageReference;
  task: AngularFireUploadTask;
  uploadProgress: Observable<number>;
  afStorage:any;
  migrationData:string="";
  masterName:string="";
  isLyricEditable:boolean=true;
  dropdownOptions:any[];
  config = {
    displayKey:"name", //if objects array passed which key to be displayed defaults to description,
    search:true,//enables the search plugin to search in the list
    placeholder:' '
    }

    track:any={
      fileName:"",
      file:null
    } 

  song:Song={
    title:"",
    artist:"",
    accompanyingArtists:null,
    type:null,
    composer:null,
    raga:null,
    tala:null,
    category:null,
    language:null,
    deities:null,
    producer:null,
    collection:null,
    duration:null,
    subscription:"",
    date:new Date(),
    track:this.track,
    lyricsId:""
  };
  tempos:any=["Original Tempo","Slower Tempo","Faster Tempo"];
  metronomes:any=["With Metronome","Without Metronome"];
  
  artists:any[];
  types:any[];
  composers:any[];
  ragas:any[];
  talas:any[];
  categories:any[];
  languages:any[];
  deities:any[];
  producers:any[];
  collections:any[];
  durations:any[];

  subscriptions:string[];
  lyricsMaster:any[];
  lyricId:any;
  lyrics:any;
  loader:boolean=true;
  
  selectedArtist:any;
  selectedAccompanyingArtists:any;
  selectedType:any;

  selectedComposer:any;
  selectedRaga:any;
  selectedTala:any;
  selectedCategory:any;
  selectedLanguage:any;
  selectedLyrics:any;
  selectedDeities:any;
  selectedProducer:any;
  selectedCollection:any;
  selectedDuration:any;
  
  errMsg:any;
  
  

  constructor(private service:AddSongService,private fireStorageService:FireStorageService, 
    private router: Router) { 
     
    }

  ngOnInit() {
    //Artist from master
    this.service.getMaster('artist').subscribe(master=>{
      let obj= new Object(master);
      this.artists=obj[0].value;
      this.dropdownOptions=obj[0].value;
      //Type from master
      this.service.getMaster('type').subscribe(master=>{
        let obj= new Object(master);
        this.types=obj[0].value;
          //Composer from master
          this.service.getMaster('composer').subscribe(master=>{
            let obj= new Object(master);
            this.composers=obj[0].value;
            //Raga from master
            this.service.getMaster('raga').subscribe(master=>{
              let obj= new Object(master);
              this.ragas=obj[0].value;
              //Tala from master
              this.service.getMaster('tala').subscribe(master=>{
                let obj= new Object(master);
                this.talas=obj[0].value;
                //category from master
                this.service.getMaster('category').subscribe(master=>{
                  let obj= new Object(master);
                  this.categories=obj[0].value;
                  //language from master
                  this.service.getMaster('language').subscribe(master=>{
                    let obj= new Object(master);
                    this.languages=obj[0].value;
                    //deities from master
                    this.service.getMaster('deities').subscribe(master=>{
                      let obj= new Object(master);
                      this.deities=obj[0].value;
                      //producers from master
                      this.service.getMaster('producer').subscribe(master=>{
                        let obj= new Object(master);
                        this.producers=obj[0].value;
                        //producers from master
                        this.service.getMaster('collection').subscribe(master=>{
                          let obj= new Object(master);
                          this.collections=obj[0].value;
                          //Duration from master
                          this.service.getMaster('duration').subscribe(master=>{
                            let obj= new Object(master);
                            this.durations=obj[0].value;
                            //lyric from master
                            this.service.getAllLyricsFromMaster().subscribe(y=>{
                              let temp=[];
                              y.docs.forEach(x=>{
                                let lyric={
                                  id:x.id,
                                  name:x.get('name'),
                                  value:x.get('value')
                                }
                                temp.push(lyric);
                              });
                              this.lyricsMaster=temp;
                              this.loader=false;
                            });
                          })
                        })
                      })
                    })
                  });
                });
              });
            });
        });
      });
    });

    

    this.subscriptions=["free","paid"];

    this.afStorage=this.fireStorageService.getAngularFireStorage();
  
    
    
  }

  uploadSongs() {
   return new Promise((respose,error)=>{
       const filePath='songs/'+this.song.artist.name+'/'+this.song.title+'/'+this.track.fileName;
       this.task=this.afStorage.upload(filePath,this.track.file).then(x=>{
       })
       this.song.track=filePath;
       respose("");
       // element.uploadProgress=this.task.percentageChanges();
    })
  }

  addSong(){

    this.inputValidation().then((error)=>{
      
      if(error){
        let temp=[]
        temp=error.toString().split('\n');
        this.errMsg=temp;
      }else{
        this.loader=true;
        this.uploadSongs().then(x=>{
            if(this.isLyricEditable==true){
              let lyric={name:this.song.title,value:this.lyrics};
              this.service.addLyrics(lyric).then(id=>{
                  this.song.lyricsId=id;
                  this.service.addSong(this.song).then(id=>{
                    let songToMaster={
                      title:this.song.title,
                      raga:this.song.raga.name,
                      tala:this.song.tala.name,
                      artist:this.song.artist.name,
                      type:this.song.type.name,
                      category:this.song.category.name,
                      songId:id
                    };
                    this.service.addToWebMaster("songs",songToMaster).then(x=>{
                      this.service.addToAppMaster(this.song).then(()=>{
                        this.loader=false;
                        this.router.navigate(['/home']);
                      })
                    })
                  })
                
                })
              
            }else{
              this.service.addSong(this.song).then(id=>{
                let songToMaster={
                  title:this.song.title,
                  raga:this.song.raga.name,
                  tala:this.song.tala.name,
                  artist:this.song.artist.name,
                  type:this.song.type.name,
                  category:this.song.category.name,
                  songId:id
                };
                this.service.addToWebMaster("songs",songToMaster).then(x=>{
                  this.service.addToAppMaster(this.song).then(()=>{
                    this.loader=false;
                    this.router.navigate(['/home']);
                  })
                })
              })
            }
        })
      }
    })
  }



  upload($event) {
    this.track.fileName=$event.target.files[0].name
    this.track.file=$event.target.files[0];
  }
 
  
  lyricsChange(){
    let temp=this.lyricsMaster.find(x=>x.id==this.selectedLyrics[0].id);
    this.song.lyricsId=temp.id;
    this.lyrics=temp.value;
    this.isLyricEditable=false;
  }

  makeLyricEditable(){
    this.isLyricEditable=true;
  }

  lyricsByTitle($event){
    let temp=this.lyricsMaster.find(x=>x.name==$event.target.value);
    if(temp!=null){
      this.song.lyricsId=temp.id;
      this.lyricId=temp.id;
      this.lyrics=temp.value;
      this.isLyricEditable=false;
    }
  }

  inputValidation(){
    let promise= new Promise((error)=>{
      let errMsg="";
      if(this.selectedArtist!=null||this.selectedArtist!=undefined){
        this.song.artist=this.selectedArtist[0];
      }else{
        errMsg=errMsg+"Artist Not Selected\n";
      }
      if(this.selectedAccompanyingArtists!=null||this.selectedAccompanyingArtists!=undefined){
       
       
        this.song.accompanyingArtists=this.selectedAccompanyingArtists;
      }
      
      if(this.selectedType!=null||this.selectedType!=undefined){
        this.song.type=this.selectedType[0];
      }else{
        errMsg=errMsg+"Type Not Selected\n";
      }
      if(this.selectedComposer!=null||this.selectedComposer!=undefined){
        this.song.composer=this.selectedComposer[0];
      }else{
        errMsg=errMsg+"Composer Not Selected\n";
      }
      if(this.selectedRaga!=null||this.selectedRaga!=undefined){
        this.song.raga=this.selectedRaga[0];
      }else{
        errMsg=errMsg+"Raga Not Selected\n";
      }
      if(this.selectedTala!=null||this.selectedTala!=undefined){
        this.song.tala=this.selectedTala[0];
      }else{
        errMsg=errMsg+"Tala Not Selected\n";
      }
      if(this.selectedCategory!=null||this.selectedCategory!=undefined){
        this.song.category=this.selectedCategory[0];
      }else{
        errMsg=errMsg+"Category Not Selected\n";
      }
      if(this.selectedLanguage!=null||this.selectedLanguage!=undefined){
        this.song.language=this.selectedLanguage[0];
      }else{
        errMsg=errMsg+"Language Not Selected\n";
      }
      if(this.selectedDeities!=null||this.selectedDeities!=undefined){
        this.song.deities=this.selectedDeities[0];
      }
      if(this.selectedProducer!=null||this.selectedProducer!=undefined){
        this.song.producer=this.selectedProducer[0];
      }else{
        errMsg=errMsg+"Producer Not Selected\n";
      }
      if(this.selectedCollection!=null||this.selectedCollection!=undefined){
        this.song.collection=this.selectedCollection[0];
      }else{
        errMsg=errMsg+"Collection Not Selected\n";
      }
      if(this.selectedDuration!=null||this.selectedDuration!=undefined){
        this.song.duration=this.selectedDuration[0];
      }
      return error(errMsg)
    })
    return promise;
  }
  
  /**
   * Uncomment to work with migration
   */

  // migrate(){
  //   var temp=this.migrationData.split(',');
  //   let arr=[];
  //   let count=1;
  //   temp.forEach(data=>{ 
  //     let obj={
  //       id:uuid(),
  //       name:data
  //     }
  //     arr.push(obj);
  //     count++;
  //   })
  //   let container={name:this.masterName,value:arr};
  //   console.log("check item :"+JSON.stringify(container));
  //   this.service.addToMaster("master",container);
  // }

  // mirgateObject(){
  //   let obj= JSON.parse(this.migrationData);
  //   console.log(obj.length);
  //   let container={name:this.masterName,value:obj};
  //   this.service.addToMaster("appMaster",container);
  // }

  // mirgrateToCollectionLyrics(){
  //   var temp=[];
  //   let count=0;
  //   temp.forEach(data=>{
  //     count++;
  //     let item:any={"id":count,"name":data.name,"description":data.description};
  //     this.service.addToMaster(this.masterName,item);
  //   })
  //   console.log("count"+count);
  // }
  
}
