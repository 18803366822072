import { Injectable } from '@angular/core';
import { FireStoreService } from '../services/fireStore/fire-store.service';

@Injectable({
  providedIn: 'root'
})
export class EditSongService {
  afStore:any;
  items:any;
  constructor(private angularFireStoreConnectionService:FireStoreService) { 
    this.afStore=angularFireStoreConnectionService.getFireStoreConnection()
  }

  getSong(id){
    return this.afStore.collection("songs").doc(id).valueChanges();
  }
  getMaster(masterName){
    return this.items=this.afStore.collection("master",ref=> ref.where('name','==',masterName)).valueChanges();
  }
  updateSong(songId,songData){
    return this.afStore.collection("songs").doc(songId).update(songData)
  }
  addLyrics(lyrics){
    return this.afStore.collection('lyrics').add(lyrics)
    .then(ref=>{
      return ref.id;
    });
  }
  updateMaster(id,data){
    return this.afStore.collection('master',ref=>ref.where('name','==','songs').limit(1))
    .get().forEach((quers)=>{
      let value=quers.docs[0].get('value');
      let index=value.findIndex(x=>x.songId==id)
      value[index].title=data.title;
      value[index].raga=data.raga;
      value[index].artist=data.artist;
      value[index].type=data.type;
      value[index].category=data.category;
      value[index].tala=data.tala;
      let _id=quers.docs[0].id;
      return this.afStore.collection('master').doc(_id).update({value:value})
    });
  }
  getAllLyricsFromMaster(){
    return this.afStore.collection("lyrics").get();
  }
}
