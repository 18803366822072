import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from 'angularfire2/firestore';
import { Observable, Observer } from 'rxjs';
import { map } from 'rxjs/operators';

interface Item {
  title: string;
  content: string;
}

@Injectable({
  providedIn: 'root'
})
export class AddSongService {

  itemsCollection: AngularFirestoreCollection<any>;
  items: Observable<any[]>;

  constructor(private db: AngularFirestore) {
  }

  addSong(song) {
    song.addedBy = JSON.parse(localStorage.getItem('userData')).email;
    song.isDeleted = false;
    return this.db.collection('songs').add(song)
      .then(ref => {
        return ref.id;
      });
  }
  addLyrics(lyrics) {
    return this.db.collection('lyrics').add(lyrics)
      .then(ref => {
        return ref.id;
      });
  }

  addToAppMaster(song) {
    return new Promise((respose, error) => {
      this.update_AToZ_Songs_RecentReleases_InAppMaster().then(() => {
        this.updateAppMaster('Type of Composition', song.type.name).then(() => {
          this.updateAppMasterArtist('Artists', song.artist.name, song.category.name).then(() => {
            this.updateAppMaster('Tala-s', song.tala.name).then(() => {
              this.updateAppMaster('Raga-s', song.raga.name).then(() => {
                this.updateAppMaster('Composers', song.composer.name).then(() => {
                  this.updateAppMaster('Categories', song.category.name).then(() => {
                    this.updateAppMaster('Languages', song.language.name).then(() => {
                      this.updateAppMaster('Producers', song.producer.name).then(() => {
                        this.updateAppMaster('Collections', song.collection.name).then(() => {
                          if(song.deities!=undefined){
                            this.updateAppMaster('Deities', song.deities.name).then(() => {
                              respose()
                            })
                          }else{
                            respose()
                          }
                        })
                      })
                    })
                  })
                })
              })
            })
          })
        })
      })
    })
  }

  updateAppMaster(attributName, attributValue) {
    return new Promise((respose, error) => {
      this.db.collection('appMaster', ref => ref.where('name', '==', attributName)).get().forEach(quersnapshots => {
        let array = quersnapshots.docs[0].data().value;
        let index = array.findIndex(x => x.name == attributValue)
        if (index == -1) {
          let tempObj = {
            name: attributValue,
            count: 1
          }
          array.push(tempObj)
        } else {
          array[index].count = array[index].count + 1;
        }
        this.db.collection('appMaster').doc(quersnapshots.docs[0].id).update({ value: array }).then(() => {
          this.db.collection('appMaster', ref => ref.where('name', '==', 'Home')).get().forEach(quersnapshots => {
            let arr = quersnapshots.docs[0].data().value;
            let index = arr.findIndex(x => x.name == attributName)
            arr[index].count = array.length;
            this.db.collection('appMaster').doc(quersnapshots.docs[0].id).update({ value: arr }).then(resp => {
              respose()
            })
          })
        })
      })
    })
  }
  updateAppMasterArtist(artistName, artistValue, categoryName) {
    return new Promise((respose, error) => {
      this.getCategory
      this.db.collection('appMaster', ref => ref.where('name', '==', artistName)).get().forEach(quersnapshots => {
        let array = quersnapshots.docs[0].data().value;
        let index = array.findIndex(x => x.name == artistValue)
        if (index == -1) {
          let tempObj = {
            name: artistValue,
            count: 1,
            category: categoryName
          }
          array.push(tempObj)
        } else {
          array[index].count = array[index].count + 1;
        }
        this.db.collection('appMaster').doc(quersnapshots.docs[0].id).update({ value: array }).then(() => {
          this.db.collection('appMaster', ref => ref.where('name', '==', 'Home')).get().forEach(quersnapshots => {
            let arr = quersnapshots.docs[0].data().value;
            let index = arr.findIndex(x => x.name == artistName)
            arr[index].count = array.length;
            this.db.collection('appMaster').doc(quersnapshots.docs[0].id).update({ value: arr }).then(resp => {
              respose()
            })
          })
        })
      })
    })
  }
  getCategory(artist) {
    return new Promise((response, error) => {
      this.db.collection('songs', ref => ref.where('artist.id', '==', artist.id)
        .where('isDeleted', '==', false)).get().forEach(query => {
          response(query.docs)
        })
    })
  }
  update_AToZ_Songs_RecentReleases_InAppMaster() {
    return new Promise((respose, error) => {
      this.db.collection('appMaster', ref => ref.where('name', '==', 'Home')).get().forEach(quersnapshots => {
        let arr = quersnapshots.docs[0].data().value;
        let index = arr.findIndex(x => x.name == 'Songs A-Z')
        arr[index].count = arr[index].count + 1;
        this.db.collection('appMaster').doc(quersnapshots.docs[0].id).update({ value: arr }).then(resp => {
          respose()
        })
      })
    })
  }

  addToWebMaster(masterName, data) {
    return this.db.collection('master', ref => ref.where('name', '==', masterName).limit(1))
      .get().forEach((quers) => {
        let value = quers.docs[0].get('value');
        let localData = {
          title: data.title,
          raga: data.raga,
          tala: data.tala,
          artist: data.artist,
          type: data.type,
          category: data.category,
          dateString: new Date().toDateString(),
          date: new Date(),
          songId: data.songId
        };
        value.push(localData);
        let _id = quers.docs[0].id;
        return this.db.collection('master').doc(_id).update({ value: value })
      });
  }

  getMaxId(data) {
    return data.reduce((max, p) => p.id > max ? p.id : max, data[0].id);
  }

  getMaster(masterName) {
    return this.items = this.db.collection("master", ref => ref.where('name', '==', masterName)).valueChanges();
  }
  addToMaster(collectionName, value) {
    this.db.collection(collectionName).add(value);
  }
  getMasterWithId() {
    alert("getting the ragas with id")
    this.itemsCollection = this.db.collection<any>('raga');
    return this.items = this.itemsCollection.snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data() as Item;
        const _id = a.payload.doc.id;
        return { _id, ...data };
      }))
    );
  }

  getAllLyricsFromMaster() {
    return this.db.collection("lyrics").get();
  }
}
